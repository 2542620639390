.post {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2rem;
}

.post-timestamp {
    flex: 1 1 100%;
    font-size: 0.8rem;
    color: grey;
    font-style: italic;
}

.post-user {
    flex: 1 1 100%;
    font-weight: bold;
}

.post-body {
    flex: 1 0 100%;
}

.post-title {
    flex: 1 0 100%;
    font-style: italic;
}

.topic-category:before {
    content: '>';
    margin-right: 0.3rem;
}

.post-topic-title {
    flex: 1 0 100%;
    font-weight: bold;
}