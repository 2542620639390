.forums {
    display: flex;
    flex-flow: row wrap;
}

.forum-list-wrapper {
    flex: 1 1 50%;
    margin-bottom: 3rem;
    min-width: 15rem;
}
