.topic-item {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1rem;
}

.topic-item-title {
    font-weight: bold;
    flex: 1 1 100%;
}

.topic-item-author {
    flex: 1 0 20%;
    font-size: 0.8rem;
}

.topic-item-cat-title {
    flex: 1 1 100%;
    font-size: 0.8rem;
    color: grey;
    font-style: italic;
}

.topic-item-meta {
    flex: 1 1 80%;
    font-size: 0.8rem;
}

.topic-item-meta span {
    font-size: 0.8rem;
}

.topic-item-meta span + span {
    margin-left: 1.5rem;
}

.topic-last-date {
    display: none;
}