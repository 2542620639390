.post-list, .topic-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-form {
    position: relative;
    z-index: 3;
    max-width: 60%;
}

.search-form form {
    box-shadow: none;
    padding: 0;
    display: flex;
    align-items: center;
    min-width: 0;
}

.search-form input {
    margin: 0;
    font-size: 1rem;
    display: inline-block;
    border: 0;
    background: #efefef;
    border-radius: 0;
    line-height: 2.5rem;
    padding: 0 0.8rem;
    min-width: 0;
    width: 35rem;
}

.search-form input:focus-visible {
    box-shadow: inset 0 0 1px #444;
    outline: none;
}

.search-form button {
    display: inline-block;
    background: #eeeeee;
    padding: 0 0.8rem;
    font-size: 1rem;
    margin: 0;
    border: 0;
    border-radius: 0;
    line-height: 2.5rem;
    vertical-align: middle;
}

.search-form button:hover {
    background: #dddddd;
    filter: none;
}
  
.search-result-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.search-result-wrapper {
    position: relative;
}

.search-result-wrapper.hidden {
    display: none;
}

.close {
    position: absolute;
    right: 2rem;
    top: 3rem;
    cursor: pointer;
}
  
.topic-result, .post-result {
    flex: 1 1 49%;
    min-width: var(--width-card);
}