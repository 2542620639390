.App-header nav {
  margin-bottom: 0;
}

.App-header {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.app-body {
  padding-top: 0;
}

blockquote {
  text-align: left;
  font-size: medium;
  box-shadow: inset 0.5rem 0 0 #eeeeee;
  margin: 1rem 0;
  padding: 1.5rem;
  padding-right: 0;
}

p {
  overflow-wrap: anywhere;
}